import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Concurrency`}</h1>
    <h2>{`2022-04-30 10:30am`}</h2>
    <p><em parentName="p">{`Duration: 4 hours`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220430T173000Z%2F20220430T213000Z&details=In%20this%204-hour%20workshop%2C%20we%20will%20explore%20the%20concepts%20of%20concurrency%20and%20multithreaded%20programming%2C%20and%20common%20patterns%20that%20we%20apply.&location=http%3A%2F%2Flovelace-labs.adadev.org&text=Concurrency%20with%20Noam%20Chitayat"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=In%20this%204-hour%20workshop%2C%20we%20will%20explore%20the%20concepts%20of%20concurrency%20and%20multithreaded%20programming%2C%20and%20common%20patterns%20that%20we%20apply.&enddt=2022-04-30T21%3A30%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-04-30T17%3A30%3A00%2B00%3A00&subject=Concurrency%20with%20Noam%20Chitayat"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p>{`In this `}<em parentName="p">{`4-hour`}</em>{` workshop, we will explore the concepts of concurrency and multithreaded programming, and common patterns that we apply. Using some example code projects, we will learn how to make our code multithreaded in order to `}<em parentName="p">{`safely`}</em>{` speed up our programs. We’ll also discover commonly encountered programming problems, and apply standard patterns to solve them.`}</p>
    <p>{`Using these patterns as a base, we’ll also discuss how some of these ideas apply when thinking about distributed system design.`}</p>
    <p><em parentName="p">{`There will be breaks, including a lunch break, during this workshop.`}</em></p>
    <h2>{`Noam Chitayat`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "545px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/83b5fc24927198533ccded5606feaa55/3ddad/Noam-Chitayat-thumbnail.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.66666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFB0lEQVQ4y02Q+VfNeRjHv/+DM2M5s1DNRIO0Sd3KUikJg4axZykNUWeEprKEyBJmzCAOw9Bmad9UumkxKVru1qKF4hpnJN1761Z8v68591qaH17n+Zzn835ezzmPUN8rUf9a4lN9LVHX855HPSPvuo+ZXomGNyM09kko+kSUb0QUvRLC/z8/DowgUt/7jkadiEJnyoifaOwbwSRU6yRUOhFBqZPMYYV5aASlQUQzCC1vQdEHja9F1AMSGiMoDaDUSyj1ormq9CIanUSTXkLQ6CXUehH1x4BBQj3wfmtFu47zyXfZ8UsCe+OvkFrcRE7NU5S6YTQGyUzTB5oNEi0GCaFZ/95swiTXGERqnhu5lP6I1YG7me4wk6mTHXG0c2Hp4o1culGGosdIcz80GUSzpNUg8dgg0W4SthtE2j40zM1BOH72Fm4yPyzGT8TKagrffmPLpIn2yJw9CNkWRcOzXp4MweN+ibZ+iQ4TBoknJqF2CJ4NQrcROvtF/gFiDv3O6NFf89X47xgz1pKJFpPwc55O5KbNBCxYzoVrGbwEnhje8XRAMtM1INHdLyE0aV/S1qOno1dPp24Q7Vs4eymV+S7u+Dg54OtkR7C3GzEBvlzeFcb21WsIDY/kmVGiyyjRbRTpNko8H5TQGiWEm9mJFNy9Ql7JZUrvp5JedJXTx49yIuQnYgLmEr1kDlHfz2Hfcj/OhQfzR2QEQRtCaH0zhHYYugdEs/y5iQEJoaDgJFevx5GZHc/trDgSrx3kWFwM8VtC2bN0LrvnuxPu7c5qjxmELZpH9plT7A/7mYrMbF4BL0wnM0kHxA/C3ONcTztARl4cF5NiSUyK5dffolng4soSp2nsmOfOencn5tjYEOA0ldPbN3Ft/z6S1gWgeqwxn+r1W94zJCHU3DtBzYMjFJccJCs/juScg5w5uZOlri54204hzN+bEE8XAt0c2e7rTuLubWTsjyZl5wbkVX+SWZhKcWUpik4t3X0g1FYeproqlvrqw5QUHCQlM4pbN2KJXfsj4fM92bNyOXuX+RPm5cLu+R4kxYRTePoAucn7kFec4+L1U6Sln+OuPIU7lWUIedmRpGfs4GbKLtJTorhTtIfyskPEB68g1NONiIXziFzoSaj3DII97ElYuwj5zcNk5kcjLzrB7eTTpKWeoDDvDPKCCwiFtXVkV1eT/3clOVXl5FWVIG+8z5GIraxynsZaN1dW2U8leOZ0Qrymc3SVL5UFN7ivrkHVoqKrq5OHikYUzSrqVWqEctVT7ik7eNDcRZmmi4L6Nsrae8gqLCLUz52NMhcCHW2J8HUlZpknxwL9uZBwlkatiOqpjtr2ftTPhmnSDtOqHUbIuVdCdnkpuRX3yKqqIremmtImDdn17YRs30uQjwdBc5wJXziLXcv9OLRyHuGBW4k438CD1n9p1vbxsO0VdW29KNp1CLUtah60KKhpaeRhm5L0aiU7T6Vh67mFMXZB+M/0YomrEwuc7Vk325UwPxkes1dg7ZuA/+ZE/pJradLqaNP2oezQITS3NNDc+pDWxw1E/5qJzdwYRln5M2qCJ186bMDW2paVM2UsdrIncNYMfOynYum4BqsZG/lsvAeWsm2s359LmfIV+r4XCBn5eRTL7xB17BKjbNYw2sKDsdZeWMjCsXDdwrjRlvjYTuYHF0cWO9thYyPDQhbGBIe1fGHtxbjxjnxuNReHxQmcSWvgP8y8m20kFm2yAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Noam Chitayat thumbnail",
            "title": "Noam Chitayat thumbnail",
            "src": "/static/83b5fc24927198533ccded5606feaa55/3ddad/Noam-Chitayat-thumbnail.png",
            "srcSet": ["/static/83b5fc24927198533ccded5606feaa55/5a46d/Noam-Chitayat-thumbnail.png 300w", "/static/83b5fc24927198533ccded5606feaa55/3ddad/Noam-Chitayat-thumbnail.png 545w"],
            "sizes": "(max-width: 545px) 100vw, 545px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Noam Chitayat has been volunteering with Ada since before there was even an Ada classroom! He has built software and supported people for nearly 15 years, at places like Microsoft, Ookla, and Remitly. He currently manages platform teams at Indeed. He enjoys music, games, and exploring the Seattle food scene with his family.`}</p>
    <p>{`Unfortunately, he cannot turn off the dad jokes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      